import { Members } from '../models/members.model';

export class GetMembers {
  static readonly type = '[Group] Get Members Details';

  constructor(public groupName: string) { }
}

export class AddMembers {
  static readonly type = '[Group] Add members';

  constructor(public groupName: string, public payload: Members) { }
}

export class DeleteMemberFromGroup {
  static readonly type = '[Group] Delete Member From Group';

  constructor(public userName: string, public groupName: string) { }
}

export class AddApplicationsAdmin {
  static readonly type = '[member] Add Application Admin';
  constructor(public ApplicationName: string, public payload: any) { }
}

export class GetApplicationsAdmin {
  static readonly type = '[member] get Application Admin';
  constructor(public ApplicationName: string) { }
}

export class DeleteApplicationsAdmin {
  static readonly type = '[member] Delete Application Admin';
  constructor(public ApplicationName: string, public UserEmail: string) { }
}


