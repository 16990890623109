import { ApplicationUser } from '../models/application.user.model';

export class AddApplicationUser {
  static readonly type = '[ApplicationUser] Add Application User';
  constructor(
    public ApplicationName: string,
    public payload: ApplicationUser
  ) {}
}

export class GetApplicationUsers {
  static readonly type = '[ApplicationUser] get Application Users';
  constructor(public ApplicationName: string) {}
}

export class DeleteApplicationUser {
  static readonly type = '[ApplicationUser] Delete Application User';
  constructor(public ApplicationName: string, public UserEmail: string) {}
}
