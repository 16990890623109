import { Group } from '../models/group.model';
import { SubscriptionModel } from '../models/subscription.model';

export class GetGroupsBychannels {
  static readonly type = '[Group] Get Group by channels';

  constructor(public applicationName: string) {}
}

export class AssignGroupToChannel {
  static readonly type = '[Group] add group to application';

  constructor(
    public applicationName: string,
    public payload: SubscriptionModel
  ) {}
}

export class DeleteGroupAssignment {
  static readonly type = '[Group] Delete Group From Application';

  constructor(public channelName: string, public groupName: string) {}
}
