import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { SubscriptionModel } from '../models/subscription.model';
import { GroupService } from 'src/app/core/services/group.service';
import {
  GetGroupsBychannels,
  DeleteGroupAssignment,
  AssignGroupToChannel,
} from '../actions/group.assignment.actions';

export class GroupAssignmentStateModel {
  subscriptions: SubscriptionModel[];
}

@State<GroupAssignmentStateModel>({
  name: 'groups',
  defaults: {
    subscriptions: [],
  },
})
@Injectable()
export class GroupAssignmentState {
  constructor(private groupService: GroupService) {}

  @Selector()
  static getGroupList(state: GroupAssignmentStateModel) {
    return state.subscriptions;
  }

  @Action(GetGroupsBychannels)
  getGroupsBychannels(
    { getState, setState }: StateContext<GroupAssignmentStateModel>,
    { applicationName }: GetGroupsBychannels
  ) {
    return this.groupService.getGroupsBychannels(applicationName).pipe(
      tap((result) => {
        const state = getState();

        setState({
          ...state,

          subscriptions: result,
        });
      })
    );
  }

  @Action(AssignGroupToChannel)
  assignGroupToChannel(
    { getState, patchState }: StateContext<GroupAssignmentStateModel>,
    { applicationName, payload }: AssignGroupToChannel
  ) {
    return this.groupService
      .assignGroupToChannel(applicationName, payload)
      .pipe(
        tap((result) => {
          const state = getState();
          patchState({
            subscriptions: [...state.subscriptions, payload],
          });
        })
      );
  }

  @Action(DeleteGroupAssignment)
  deleteGroupAssignment(
    { getState, setState }: StateContext<GroupAssignmentStateModel>,
    { channelName, groupName }: DeleteGroupAssignment
  ) {
    return this.groupService.deleteGroupAssignment(channelName, groupName).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.subscriptions.filter(
          (item) => item.group_name !== groupName || item.channel_name !== channelName
        );
        setState({
          ...state,
          subscriptions: filteredArray,
        });
      })
    );
  }
}
