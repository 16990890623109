import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Channel } from 'src/app/shared/models/channel.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  ApiUrl = '';
  constructor(private httpClient: HttpClient) {
    this.ApiUrl = environment.ApiUrl + '/application';
  }

  fetchChannels(applicationName: string, channelName: string) {
    return this.httpClient.get<Channel[]>(
      this.ApiUrl + '/' + applicationName + '/' + channelName
    );
  }

  addupdateChannel(
    applicationName: string,
    channelName: string,
    payload: Channel
  ) {
    return this.httpClient.put<Channel>(
      this.ApiUrl + '/' + applicationName + '/' + channelName,
      payload
    );
  }

  deleteChannel(applicationName: string, channelName: string) {
    return this.httpClient.delete(
      this.ApiUrl + '/' + applicationName + '/' + channelName
    );
  }
}
