import { Channel } from '../models/channel.model'

export class AddUpdateChannel {
    static readonly type = '[Channel] Add Update';

    constructor(public applicationlName: string, public channelName: string, public payload: Channel) {
    }
}

export class GetChannels {
    static readonly type = '[Channel] Get Channels';

    constructor(public applicationlName: string, public channelName: string) {
    }
}



export class DeleteChannel {
    static readonly type = '[Channel] Delete Channel';

    constructor(public applicationName: string, public channelName: string) {
    }
}

