import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Token } from 'src/app/shared/models/token.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  ApiUrl = '';
  constructor(private httpClient: HttpClient) {
    this.ApiUrl = environment.ApiUrl + '/token';
  }

  fetchTokens() {
    return this.httpClient.get<Token[]>(this.ApiUrl);
  }

  deleteToken(token: string) {
    return this.httpClient.delete(this.ApiUrl + '/' + token);
  }
}
