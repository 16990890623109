import { Application } from '../models/application.model';

export class AddUpdateApplication {
  static readonly type = '[Application] Add Update';

  constructor(public UserEmail: string, public payload: Application) {}
}

export class GetApplications {
  static readonly type = '[Application] Get Applications';
}

export class GetApplicationsByUser {
  static readonly type = '[Application] Get Applications by user';
  constructor(public UserEmail: string) {}
}

export class GetApplicationsDetails {
  static readonly type = '[Application] Get Application Details';
  constructor(public ApplicationName: string) {}
}

export class ApproveApplication {
  static readonly type = '[Application] Approve application';

  constructor(public payload: Application) {}
}
export class DeleteApplication {
  static readonly type = '[Application] Delete Application';

  constructor(public ApplicationName: string) {}
}

export class RotateApplicationkey {
  static readonly type = '[Application] rotate application key';

  constructor(public payload: Application) {}
}
