import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class SpinnerComponent implements OnInit {
  role = false;

  constructor(private authService: AuthService, private router: Router) {}
  ngOnInit() {
    this.authService.getCurrentUser().then((user) => {
      console.log(user.attributes.email);
      const roles = user.attributes['custom:roles'];
      if (roles == 'Admin') {
        this.router.navigate(['/administrator']);
      }
      if (roles == 'Developer') {
        this.router.navigate(['/developer']);
      }
      if (roles.includes('Admin') && roles.includes('Developer')) {
        this.role = true;
      }
    });
  }
}
