import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ApplicationService } from '../services/application.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class PermissionGuard {
  private allowedToacessPage = true;
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private applicationService: ApplicationService,
    private router: Router
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.checkAccess(state);
    return this.allowedToacessPage;
  }

  async checkAccess(state: RouterStateSnapshot) {
    // Get the current user
    const user = await this.authService.getCurrentUser();
    const userEmail = user.username.replace('DraexlmaierAzureAD_', '');
    // Get the applications associated with the user
    await this.applicationService
      .getApplicationByUser(userEmail)
      .subscribe((applications) => {
        // Check if the current URL includes any of the user's applications
        const appFound = applications.some((app) =>
          state.url.includes(app.application_name)
        );
        // If no application is found, handle unauthorized access
        if (!appFound) {
          this.handleUnauthorizedAccess();
        }
      });
  }
  // Handle unauthorized access
  handleUnauthorizedAccess(): void {
    this.allowedToacessPage = false;
    this.router.navigate(['/spinner']);
  }
}
