import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  AddApplicationsAdmin,
  DeleteApplicationsAdmin,
  GetApplicationsAdmin,
} from '../actions/admin.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Admin } from '../models/admin.model';
import { AdminService } from 'src/app/core/services/admin.service';

export class AdminStateModel {
  admins: Admin[];
}

@State<AdminStateModel>({
  name: 'admins',
  defaults: {
    admins: [],
  },
})
@Injectable()
export class adminState {
  constructor(private adminService: AdminService) { }

  @Selector()
  static getAdminList(state: AdminStateModel) {
    return state.admins;
  }


  @Action(AddApplicationsAdmin)
  addApplicationsAdmin(
    { getState, patchState }: StateContext<AdminStateModel>,
    { ApplicationName, payload }: AddApplicationsAdmin
  ) {
    return this.adminService.addApplicationsAdmin(ApplicationName, payload).pipe(
      tap(() => {
        const state = getState();
        patchState({
          admins: [...state.admins, payload],
        });
      })
    );
  }

  @Action(GetApplicationsAdmin)
  getApplicationsAdmin(
    { getState, setState }: StateContext<AdminStateModel>,
    { ApplicationName }: GetApplicationsAdmin
  ) {
    return this.adminService.getApplicationsAdmin(ApplicationName).pipe(
      tap((result) => {
        const state = getState();
        console.log('admins', result);
        setState({
          ...state,

          admins: result,
        });
      })
    );
  }


  @Action(DeleteApplicationsAdmin)
  deleteApplicationsAdmin(
    { getState, setState }: StateContext<AdminStateModel>,
    { ApplicationName, UserEmail }: DeleteApplicationsAdmin
  ) {
    return this.adminService.deleteApplicationsAdmin(ApplicationName, UserEmail).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.admins.filter(
          (item) => item.admin_email !== UserEmail
        );
        setState({
          ...state,
          admins: filteredArray,
        });
      })
    );
  }
}
