import { Admin } from '../models/admin.model';

export class AddApplicationsAdmin {
  static readonly type = '[Admin] Add Application Admin';
  constructor(public ApplicationName: string, public payload: any) {}
}

export class GetApplicationsAdmin {
  static readonly type = '[Admin] get Application Admin';
  constructor(public ApplicationName: string) {}
}

export class DeleteApplicationsAdmin {
  static readonly type = '[Admin] Delete Application Admin';
  constructor(public ApplicationName: string, public UserEmail: string) {}
}
