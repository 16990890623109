import { User } from '../models/user.model';

export class AddUpdateUser {
  static readonly type = '[User] Add Update User to application';

  constructor(public payload: User) {}
}

export class GetUser {
  static readonly type = '[User] Get User details';
  constructor(public PK: string) {}
}

export class GetUsers {
  static readonly type = '[User] Get Users';
}

export class DeleteUser {
  static readonly type = '[User] Delete User';
  constructor(public PK: string) {}
}
