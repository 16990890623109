const hasLocalhost = (hostname: string) =>
  Boolean(
    hostname.match(/localhost/) ||
    hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/)
  );
const isLocalhost = hasLocalhost(window.location.hostname);
export const environment = {
  stage: isLocalhost ? 'local' : 'dev',
  production: false,
  ApiUrl: '  https://hzza6144yg.execute-api.eu-central-1.amazonaws.com/prod',
  amplify: {
    Auth: {
      identityPoolRegion: 'eu-central-1',
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_B7ts3L0ft',
      userPoolWebClientId: '21utioivlq0587b66f2358j3p7',
      oauth: {
        domain: 'd-push-azure-ad-dev.auth.eu-central-1.amazoncognito.com',
        scope: [
          'phone',
          'email',
          'openid',
          'profile',
          'aws.cognito.signin.user.admin',
        ],
        redirectSignIn: isLocalhost
          ? 'http://localhost:4200'
          : 'https://portal.d-push-dev.draexlmaier.solutions',
        redirectSignOut: isLocalhost
          ? 'http://localhost:4200'
          : 'https://portal.d-push-dev.draexlmaier.solutions',

        responseType: 'code',
      },
    },
  },
};