import { State, Action, StateContext, Selector } from '@ngxs/store';
import { DeleteToken, GetTokens } from '../actions/token.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Token } from '../models/token.model';
import { TokenService } from 'src/app/core/services/token.service';

export class TokenStateModel {
  tokens: Token[];
  selectedToken: Token | null;
}

@State<TokenStateModel>({
  name: 'tokens',
  defaults: {
    tokens: [],
    selectedToken: null,
  },
})
@Injectable()
export class TokenState {
  constructor(private tokenService: TokenService) {}

  @Selector()
  static getTokenList(state: TokenStateModel) {
    return state.tokens;
  }

  @Action(GetTokens)
  getTokens({ getState, setState }: StateContext<TokenStateModel>) {
    return this.tokenService.fetchTokens().pipe(
      tap((result) => {
        const state = getState();

        setState({
          ...state,

          tokens: result,
        });
      })
    );
  }

  @Action(DeleteToken)
  deleteToken(
    { getState, setState }: StateContext<TokenStateModel>,
    { token }: DeleteToken
  ) {
    return this.tokenService.deleteToken(token).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.tokens.filter(
          (item) => item.Token !== String(token)
        );
        setState({
          ...state,
          tokens: filteredArray,
        });
      })
    );
  }
}
