import { Group } from '../models/group.model';

export class AddUpdateGroup {
  static readonly type = '[Group] Add Update Group To Application';

  constructor(public applicationName: string, public payload: Group) {}
}

export class GetGroupsByapplication {
  static readonly type = '[Group] Get group by application';
  constructor(public applicationName: string) {}
}

export class GetGroupsBychannels {
  static readonly type = '[Group] Get Group by channels';

  constructor(public applicationName: string) {}
}

export class AssignGroupToChannel {
  static readonly type = '[Group] add group to application';

  constructor(public applicationName: string, public payload: Group) {}
}

export class GetGroupDetails {
  static readonly type = '[Group] Get Group Details';

  constructor(public groupName: string) {}
}
export class DeleteGroup {
  static readonly type = '[Group] Delete Group';

  constructor(public groupName: string) {}
}
