import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Application } from 'src/app/shared/models/application.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  ApiUrl = '';
  constructor(private httpClient: HttpClient) {
    this.ApiUrl = environment.ApiUrl + '/application';
  }

  addupdateApplication(UserEmail: string, payload: Application) {
    return this.httpClient.put<Application>(
      this.ApiUrl + '/userapplication/' + UserEmail,
      payload
    );
  }

  approveApplication(payload: Application) {
    return this.httpClient.put<Application>(this.ApiUrl, payload);
  }

  deleteApplication(ApplicationName: string) {
    return this.httpClient.delete(this.ApiUrl + '/' + ApplicationName);
  }

  fetchApplications() {
    console.log(this.ApiUrl);
    return this.httpClient.get<Application[]>(this.ApiUrl);
  }

  getApplicationByUser(UserEmail: string) {
    return this.httpClient.get<Application[]>(
      this.ApiUrl + '/userapplication/' + UserEmail
    );
  }
  getApplicationDetails(ApplicationName: string) {
    return this.httpClient.get<Application[]>(
      this.ApiUrl + '/' + ApplicationName
    );
  }

  rotateApplicationkey(payload: Application) {
    return this.httpClient.put<Application>(
      this.ApiUrl + '/userapplication',
      payload
    );
  }
}
