import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  ApiUrl = '';
  constructor(private httpClient: HttpClient) {
    this.ApiUrl = environment.ApiUrl + '/user';
  }

  getUser(Email: string) {
    return this.httpClient.get<User[]>(this.ApiUrl + '/' + Email);
  }

  fetchUsers() {
    return this.httpClient.get<User[]>(this.ApiUrl);
  }
  addupdateUser(payload: User) {
    return this.httpClient.put<User>(this.ApiUrl, payload);
  }

  deleteUser(Email: string) {
    return this.httpClient.delete(this.ApiUrl + '/' + Email);
  }
}
