import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  AddUpdateApplication,
  ApproveApplication,
  DeleteApplication,
  GetApplications,
  GetApplicationsByUser,
  GetApplicationsDetails,
  RotateApplicationkey,
} from '../actions/application.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Application } from '../models/application.model';
import { ApplicationService } from 'src/app/core/services/application.service';

export class ApplicationStateModel {
  applications: Application[];
}

@State<ApplicationStateModel>({
  name: 'applications',
  defaults: {
    applications: [],
  },
})
@Injectable()
export class ApplicationState {
  constructor(private applicationService: ApplicationService) { }

  @Selector()
  static getApplicationList(state: ApplicationStateModel) {
    return state.applications;
  }

  @Action(GetApplications)
  getApplications({ getState, setState }: StateContext<ApplicationStateModel>) {
    return this.applicationService.fetchApplications().pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,

          applications: result,
        });
      })
    );
  }
  @Action(GetApplicationsByUser)
  getApplicationsByUser(
    { getState, setState }: StateContext<ApplicationStateModel>,
    { UserEmail }: GetApplicationsByUser
  ) {
    let userapp: Application[];

    return this.applicationService.getApplicationByUser(UserEmail).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,

          applications: result,
        });
      })
    );
  }

  @Action(GetApplicationsDetails)
  getApplicationDetails(
    { getState, setState }: StateContext<ApplicationStateModel>,
    { ApplicationName }: GetApplicationsDetails
  ) {
    return this.applicationService.getApplicationDetails(ApplicationName).pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,

          applications: result,
        });
      })
    );
  }

  @Action(AddUpdateApplication)
  addUpdateApplication(
    { getState, patchState }: StateContext<ApplicationStateModel>,
    { UserEmail, payload }: AddUpdateApplication
  ) {
    return this.applicationService
      .addupdateApplication(UserEmail, payload)
      .pipe(
        tap(() => {
          const state = getState();
          patchState({
            applications: [...state.applications, payload],
          });
        })
      );
  }
  @Action(ApproveApplication)
  approveApplication(
    { getState, setState }: StateContext<ApplicationStateModel>,
    { payload }: ApproveApplication
  ) {
    return this.applicationService.approveApplication(payload).pipe(
      tap(() => {
        const state = getState();
        const appList = [...state.applications];
        const appIndex = appList.findIndex(
          (item) => item.application_name === payload.application_name
        );
        appList[appIndex] = payload;
        setState({
          ...state,
          applications: appList,
        });
      })
    );
  }

  @Action(RotateApplicationkey)
  rotateApplicationkey(
    { getState, setState }: StateContext<ApplicationStateModel>,
    { payload }: RotateApplicationkey
  ) {
    return this.applicationService.rotateApplicationkey(payload).pipe(
      tap(() => {
        const state = getState();
        const appList = [...state.applications];
        const appIndex = appList.findIndex(
          (item) => item.application_name === payload.application_name
        );
        appList[appIndex] = payload;
        setState({
          ...state,
          applications: appList,
        });
      })
    );
  }

  @Action(DeleteApplication)
  deleteApplication(
    { getState, setState }: StateContext<ApplicationStateModel>,
    { ApplicationName }: DeleteApplication
  ) {
    return this.applicationService.deleteApplication(ApplicationName).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.applications.filter(
          (item) => item.application_name !== ApplicationName
        );
        setState({
          ...state,
          applications: filteredArray,
        });
      })
    );
  }




}
