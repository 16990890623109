import { Token } from '../models/token.model';

export class GetTokens {
  static readonly type = '[Token] Get Token';
}

export class DeleteToken {
  static readonly type = '[Token] Delete Token';
  constructor(public token: string) {}
}
