import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/_guards/auth.guards';
import { SpinnerComponent } from './core/spinner/spinner.component';

export const routes: Routes = [
  { path: '', redirectTo: 'spinner', pathMatch: 'full' },
  {
    path: 'public',
    loadChildren: () =>
      import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'spinner',
    component: SpinnerComponent,
    canActivate: [AuthGuard],
    data: {
      role: '',
    },
  },
  {
    path: 'administrator',
    loadChildren: () =>
      import('./administrator/administrator.module').then(
        (m) => m.AdministratorModule
      ),
    canActivate: [AuthGuard],
    data: {
      role: 'Admin',
    },
  },
  {
    path: 'developer',
    loadChildren: () =>
      import('./developer/developer.module').then((m) => m.DeveloperModule),
    canActivate: [AuthGuard],
    data: {
      role: 'Developer',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
