import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ApplicationUser } from 'src/app/shared/models/application.user.model';
@Injectable({
  providedIn: 'root',
})
export class ApplicationUserService {
  ApiUrl = '';
  constructor(private httpClient: HttpClient) {
    this.ApiUrl = environment.ApiUrl + '/application';
  }

  addApplicationsUser(ApplicationName: string, payload: ApplicationUser) {
    return this.httpClient.put<ApplicationUser>(
      this.ApiUrl + '/' + ApplicationName + '/user',
      payload
    );
  }

  getApplicationsUser(ApplicationName: string) {
    return this.httpClient.get<ApplicationUser[]>(
      this.ApiUrl + '/' + ApplicationName + '/user'
    );
  }
  deleteApplicationsUser(ApplicationName: string, UserEmail: string) {
    return this.httpClient.delete(
      this.ApiUrl + '/' + ApplicationName + '/user/' + UserEmail
    );
  }
}
