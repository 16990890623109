import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/shared/models/group.model';
import { Members } from 'src/app/shared/models/members.model';
import { SubscriptionModel } from 'src/app/shared/models/subscription.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  ApiUrl = '';
  constructor(private httpClient: HttpClient) {
    this.ApiUrl = environment.ApiUrl + '/group';
  }

  getGroupsByapplication(applicationName: string) {
    return this.httpClient.get<Group[]>(
      this.ApiUrl + '/application/' + applicationName
    );
  }

  assignGroupToChannel(applicationName: string, payload: SubscriptionModel) {
    return this.httpClient.put<SubscriptionModel>(
      environment.ApiUrl + '/subscriptions/' + applicationName,
      payload
    );
  }
  getGroupsBychannels(applicationName: string) {
    return this.httpClient.get<SubscriptionModel[]>(
      environment.ApiUrl + '/subscriptions/' + applicationName
    );
  }

  getGroupMembers(groupName: string) {
    return this.httpClient.get<Members[]>(this.ApiUrl + '/' + groupName);
  }

  addMemberToGroup(groupName: string, payload: Members) {
    return this.httpClient.put<Group>(this.ApiUrl + '/' + groupName, payload);
  }

  addUpdateGroup(applicationName: string, payload: Group) {
    return this.httpClient.put<Group>(
      this.ApiUrl + '/application/' + applicationName,
      payload
    );
  }

  deleteGroup(groupName: string) {
    return this.httpClient.delete(this.ApiUrl + '/' + groupName);
  }

  deleteGroupAssignment(channelName: string, groupName: string) {
    return this.httpClient.delete(
      environment.ApiUrl +
        '/subscriptions/group/' +
        groupName +
        '/channel/' +
        channelName
    );
  }

  deleteMemberFromGroup(userName: string, groupName: string) {
    return this.httpClient.delete(
      this.ApiUrl + '/' + groupName + '/groupMembers/' + userName
    );
  }

  addApplicationsAdmin(ApplicationName: string, payload: Members) {
    return this.httpClient.put<Members>(
      environment.ApiUrl + '/application/' + ApplicationName + '/admin',
      payload
    );
  }

  getApplicationsAdmin(ApplicationName: string) {
    return this.httpClient.get<Members[]>(
      environment.ApiUrl + '/application/' + ApplicationName + '/admin'
    );
  }
  deleteApplicationsAdmin(ApplicationName: string, UserEmail: string) {
    return this.httpClient.delete(
      environment.ApiUrl +
        '/application/' +
        ApplicationName +
        '/admin/' +
        UserEmail
    );
  }
}
