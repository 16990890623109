import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  AddApplicationUser,
  DeleteApplicationUser,
  GetApplicationUsers,
} from '../actions/application.user.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApplicationUser } from '../models/application.user.model';
import { ApplicationUserService } from 'src/app/core/services/application.user.service';

export class ApplicationUserStateModel {
  applicationUsers: ApplicationUser[];
}

@State<ApplicationUserStateModel>({
  name: 'applicationUsers',
  defaults: {
    applicationUsers: [],
  },
})
@Injectable()
export class applicationUserState {
  constructor(private applicationUserService: ApplicationUserService) {}

  @Selector()
  static getApplicationUsersList(state: ApplicationUserStateModel) {
    return state.applicationUsers;
  }

  @Action(AddApplicationUser)
  addApplicationsUser(
    { getState, patchState }: StateContext<ApplicationUserStateModel>,
    { ApplicationName, payload }: AddApplicationUser
  ) {
    return this.applicationUserService
      .addApplicationsUser(ApplicationName, payload)
      .pipe(
        tap(() => {
          const state = getState();
          patchState({
            applicationUsers: [...state.applicationUsers, payload],
          });
        })
      );
  }

  @Action(GetApplicationUsers)
  getApplicationUsers(
    { getState, setState }: StateContext<ApplicationUserStateModel>,
    { ApplicationName }: GetApplicationUsers
  ) {
    return this.applicationUserService
      .getApplicationsUser(ApplicationName)
      .pipe(
        tap((result) => {
          const state = getState();
          console.log('admins', result);
          setState({
            ...state,

            applicationUsers: result,
          });
        })
      );
  }

  @Action(DeleteApplicationUser)
  deleteApplicationUser(
    { getState, setState }: StateContext<ApplicationUserStateModel>,
    { ApplicationName, UserEmail }: DeleteApplicationUser
  ) {
    return this.applicationUserService
      .deleteApplicationsUser(ApplicationName, UserEmail)
      .pipe(
        tap(() => {
          const state = getState();
          const filteredArray = state.applicationUsers.filter(
            (item) => item.email !== UserEmail
          );
          setState({
            ...state,
            applicationUsers: filteredArray,
          });
        })
      );
  }
}
