import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Channel } from '../models/channel.model';
import { ChannelService } from 'src/app/core/services/channel.service';
import {
  AddUpdateChannel,
  DeleteChannel,
  GetChannels,
} from '../actions/channel.actions';

export class ChannelStateModel {
  channels: Channel[];
  selectedGroup: Channel | null;
}

@State<ChannelStateModel>({
  name: 'channels',
  defaults: {
    channels: [],
    selectedGroup: null,
  },
})
@Injectable()
export class ChannelState {
  constructor(private channelService: ChannelService) {}

  @Selector()
  static getChannelList(state: ChannelStateModel) {
    return state.channels;
  }

  @Action(GetChannels)
  getChannels(
    { getState, setState }: StateContext<ChannelStateModel>,
    { applicationlName, channelName }: GetChannels
  ) {
    return this.channelService
      .fetchChannels(applicationlName, channelName)
      .pipe(
        tap((result) => {
          const state = getState();

          setState({
            ...state,

            channels: result,
          });
        })
      );
  }

  @Action(AddUpdateChannel)
  addUpdateChannel(
    { getState, patchState, setState }: StateContext<ChannelStateModel>,
    { applicationlName, channelName, payload }: AddUpdateChannel
  ) {
    return this.channelService
      .addupdateChannel(applicationlName, channelName, payload)
      .pipe(
        tap((result) => {
          const state = getState();
          const index = state.channels.findIndex(
            (channel) => channel.channel_name === payload.channel_name
          );
          if (index == -1) {
            patchState({
              channels: [...state.channels, payload],
            });
          } else {
            const newArray = state.channels;
            newArray[index].Description = payload.Description;
            setState({
              ...state,
              channels: newArray,
            });
          }
        })
      );
  }

  @Action(DeleteChannel)
  deleteChannel(
    { getState, setState }: StateContext<ChannelStateModel>,
    { applicationName, channelName }: DeleteChannel
  ) {
    return this.channelService.deleteChannel(applicationName, channelName).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.channels.filter(
          (item) => item.channel_name !== channelName
        );
        setState({
          ...state,
          channels: filteredArray,
        });
      })
    );
  }
}
