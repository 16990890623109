import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const Poop_Data = {
  UserPoolId: environment.amplify.Auth.userPoolId,
  ClientId: environment.amplify.Auth.userPoolWebClientId,
};
const userPool = new CognitoUserPool(Poop_Data);

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn: BehaviorSubject<boolean>;

  constructor(private router: Router, private zone: NgZone) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  /** signup */

  /** confirm code */

  /** signin */

  public getToken(): Observable<any> {
    return from(Auth.currentSession()).pipe(
      map((session: CognitoUserSession) => session.getIdToken().getJwtToken())
    );
  }

  public getUsername(): Observable<any> {
    const observableFromPromise = from(Auth.currentUserInfo()).pipe(
      tap(() => this.loggedIn.next(true))
    );
    return observableFromPromise;
  }
  //verify if user is authenticated and also if he has the right role
  async isAuthenticated(
    route: ActivatedRouteSnapshot,
    url: any
  ): Promise<boolean> {
    console.log('route', route);
    console.log('url', url);
    return Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        const userRole = user.attributes['custom:roles'];
        console.log('role', userRole);
        console.log('role', route.data.role);
        if (route.data.role && userRole.indexOf(route.data.role) === -1) {
          this.router.navigate(['/spinner']);
          return false;
        }
        return true;
      })
      .catch((err) => {
        this.router.navigate(['/public/signin']);
        return false;
      });
  }

  public federatedSignin() {
    const observableFromPromise = from(
      Auth.federatedSignIn({
        customProvider: 'AzureADProvider',
      })
    ).pipe(
      tap(() => {
        this.router.navigate(['/']);
      })
    );
    return observableFromPromise;
  }

  getCurrentUser() {
    return Auth.currentUserInfo()
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }

  setLoggedIn(value: boolean) {
    this.loggedIn.next(value);
    console.log(
      'redirectsignin',
      environment.amplify.Auth.oauth.redirectSignIn
    );
  }

  getLoggedIn() {
    return this.loggedIn.value;
  }

  public signOut() {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      console.log(cognitoUser);
      cognitoUser.signOut();
      this.router.navigate(['/']);
    }
  }
}
