/// <reference types="@angular/localize" />

import { CdkColumnDef } from '@angular/cdk/table';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { Amplify } from 'aws-amplify';
import { AppRoutingModule, routes } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { AuthGuard } from './app/core/_guards/auth.guards';
import { PermissionGuard } from './app/core/_guards/permission.guard';
import { AuthInterceptor } from './app/core/interceptor/auth.interceptor';
import { AuthService } from './app/core/services/auth.service';
import { UserService } from './app/core/services/user.service';
import { adminState } from './app/shared/states/admin.state';
import { ApplicationState } from './app/shared/states/application.state';
import { applicationUserState } from './app/shared/states/application.user.state';
import { ChannelState } from './app/shared/states/channel.state';
import { GroupAssignmentState } from './app/shared/states/group.assignment.state';
import { GroupState } from './app/shared/states/group.state';
import { membersState } from './app/shared/states/members.state';
import { TokenState } from './app/shared/states/token.state';
import { UserState } from './app/shared/states/user.state';
import { environment } from './environments/environment';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      AmplifyAuthenticatorModule,
      NgbModule,
      FormsModule,
      ReactiveFormsModule,
      NgxsModule.forRoot([
        UserState,
        ApplicationState,
        GroupState,
        TokenState,
        ChannelState,
        membersState,
        GroupAssignmentState,
        adminState,
        applicationUserState,
      ])
    ),
    AuthService,
    AuthGuard,
    UserService,
    CdkColumnDef,
    PermissionGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes),
  ],
}).catch((err) => console.error(err));
Amplify.configure(environment.amplify);
