<div class="backgroudtheme">
    <div class="spinner " *ngIf="!role">
        <div class="spinner-grow text-info" role="status" style="width: 3rem; height: 3rem">
            <span class="sr-only">Loading...</span>
        </div>
    </div>


    <div class="container center " *ngIf="role">

        <a class="card4" href="/administrator">

            <h3>Administrator</h3>
            <p class="small">Provides ablility to mange All applications .</p>
            <div class="dimmer"></div>
            <div class="go-corner" href="#">
                <div class="go-arrow">
                    →
                </div>
            </div>
        </a>
        <a class="card4" href="/developer">

            <h3>Developer</h3>
            <p class="small">Provides ablility to mange your applications .</p>
            <div class="dimmer"></div>
            <div class="go-corner" href="#">
                <div class="go-arrow">
                    →
                </div>
            </div>
        </a>

    </div>
</div>