import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Admin } from 'src/app/shared/models/admin.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  ApiUrl = '';
  constructor(private httpClient: HttpClient) {
    this.ApiUrl = environment.ApiUrl + '/application';
  }

  addApplicationsAdmin(ApplicationName: string, payload: Admin) {
    return this.httpClient.put<Admin>(
      this.ApiUrl + '/' + ApplicationName + '/admin',
      payload
    );
  }

  getApplicationsAdmin(ApplicationName: string) {
    return this.httpClient.get<Admin[]>(
      this.ApiUrl + '/' + ApplicationName + '/admin'
    );
  }
  deleteApplicationsAdmin(ApplicationName: string, UserEmail: string) {
    return this.httpClient.delete(
      this.ApiUrl + '/' + ApplicationName + '/admin/' + UserEmail
    );
  }
}
