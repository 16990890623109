import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Group } from '../models/group.model';
import { GroupService } from 'src/app/core/services/group.service';
import {
  DeleteGroup,
  GetGroupsByapplication,
  AddUpdateGroup,

} from '../actions/group.actions';

export class GroupStateModel {
  groups: Group[];
}

@State<GroupStateModel>({
  name: 'groupsAssignment',
  defaults: {
    groups: [],
  },
})
@Injectable()
export class GroupState {
  constructor(private groupService: GroupService) { }

  @Selector()
  static getGroupList(state: GroupStateModel) {
    return state.groups;
  }

  @Action(GetGroupsByapplication)
  getGroupsByapplication(
    { getState, setState }: StateContext<GroupStateModel>,
    { applicationName }: GetGroupsByapplication
  ) {
    return this.groupService.getGroupsByapplication(applicationName).pipe(
      tap((result) => {
        const state = getState();

        setState({
          ...state,
          groups: result,
        });
      })
    );
  }

  @Action(AddUpdateGroup)
  addUpdateGroup(
    { getState, patchState, setState }: StateContext<GroupStateModel>,
    { applicationName, payload }: AddUpdateGroup
  ) {
    return this.groupService.addUpdateGroup(applicationName, payload).pipe(
      tap(() => {
        const state = getState();
        const index = state.groups.findIndex(
          (group) => group.group_name === payload.group_name
        );
        if (index == -1) {
          patchState({
            groups: [...state.groups, payload],
          });
        }
        else {

          const newArray = state.groups
          newArray[index].Description = payload.Description
          setState({
            ...state,
            groups: newArray,
          });
        }

      })
    );
  }

  @Action(DeleteGroup)
  deleteGroup(
    { getState, setState }: StateContext<GroupStateModel>,
    { groupName }: DeleteGroup
  ) {
    return this.groupService.deleteGroup(groupName).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.groups.filter(
          (item) => item.group_name !== groupName
        );
        setState({
          ...state,
          groups: filteredArray,
        });
      })
    );
  }





}
