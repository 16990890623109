import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  AddUpdateUser,
  DeleteUser,
  GetUser,
  GetUsers,
} from '../actions/user.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { UserService } from 'src/app/core/services/user.service';

export class UserStateModel {
  users: User[];
}

@State<UserStateModel>({
  name: 'users',
  defaults: {
    users: [],
  },
})
@Injectable()
export class UserState {
  constructor(private userService: UserService) { }

  @Selector()
  static getUserList(state: UserStateModel) {
    return state.users;
  }

  @Action(GetUsers)
  getUsers({ getState, setState }: StateContext<UserStateModel>) {
    return this.userService.fetchUsers().pipe(
      tap((result) => {
        const state = getState();
        setState({
          ...state,
          users: result,
        });
      })
    );
  }

  @Action(GetUser)
  getUser(
    { getState, setState }: StateContext<UserStateModel>,
    { PK }: GetUser
  ) {
    return this.userService.getUser(PK).pipe(
      tap((result) => {
        const state = getState();

        setState({
          ...state,
          users: result,
        });
      })
    );
  }

  @Action(AddUpdateUser)
  addUpdateUser(
    { getState, patchState }: StateContext<UserStateModel>,
    { payload }: AddUpdateUser
  ) {
    return this.userService.addupdateUser(payload).pipe(
      tap(() => {
        const state = getState();
        patchState({
          users: [...state.users, payload],
        });
      })
    );
  }

  @Action(DeleteUser)
  deleteUser(
    { getState, setState }: StateContext<UserStateModel>,
    { PK }: DeleteUser
  ) {
    return this.userService.deleteUser(PK).pipe(
      tap(() => {
        const state = getState();
        const filteredArray = state.users.filter(
          (item) => item.PK !== String('user#' + PK)
        );
        setState({
          ...state,
          users: filteredArray,
        });
      })
    );
  }
}
